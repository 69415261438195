import { sendRequest } from "../utility/utility";
import { getItem } from "../utility/localStorageControl";


const getTowns  = async (data) => {
    const token = getItem("token");  
    const route = "getMunicipalities";
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: JSON.stringify(data)
    };
    return await sendRequest(route, request);
}

const getDataByCP  = async (data) => {
    const token = getItem("token");  
    const route = "sepomex";
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
            Accept: "application/json"
        },
        body: JSON.stringify(data)
    };
    return await sendRequest(route, request);
}


export {
    getTowns,
    getDataByCP
}